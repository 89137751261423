import { qspost } from '@/config/axios';

export const storySelectList = data => qspost("request?", data, "GetStorySelect");
export const storyList = data => qspost("request?", data, "GetStory");
export const storyAdd = data => qspost("request?", data, "AddStory");
export const storyEdit = data => qspost("request?", data, "UpdateStory");
export const storyDel = data => qspost("request?", data, "DelStory");
export const storyStatus = data => qspost("request?", data, "BatchShelvesStory");

export const chapterAdd = data => qspost("request?", data, "AddStoryChapters");
export const chapterEdit = data => qspost("request?", data, "UpdateStoryChapters");
export const chapterList = data => qspost("request?", data, "GetStoryChapters");
export const chapterDel = data => qspost("request?", data, "DelStoryChapters");
export const chapterStatus = data => qspost("request?", data, "BatchShelvesChapter");
export const chapterIsFree = data => qspost("request?", data, "IsFreeStoryChapter");

