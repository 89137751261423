<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">ID：</span>
        <el-input class="width_100" v-model="search.id" placeholder="请输入小说ID" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">标题：</span>
        <el-input class="width_250" v-model="search.title" placeholder="请输入标题关键字" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">专辑：</span>
        <el-select v-model="search.story_id" filterable placeholder="全部" size="medium" clearable>
          <el-option label="全部" value="0"></el-option>
          <el-option v-for="item in storySelectList" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="search.status" placeholder="全部" size="medium" clearable>
          <el-option key="-1" label="全部" value="-1"></el-option>
          <el-option v-for="item in statusList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getChapterList()">查询</el-button>
      </div>
      <br>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="chapterShow()" class="el-icon-circle-plus">新增小说章节</el-button>
        <el-button type="primary" size="medium" class="el-icon-tickets" @click="showstoryList()" >专辑管理</el-button>
        <el-button type="primary" size="medium" @click="chapterStatusMulti(1)">批量上架</el-button>
        <el-button type="primary" size="medium" @click="chapterStatusMulti(2)">批量下架</el-button>
        <el-button type="primary" size="medium" @click="delByMultiSelect()">批量删除</el-button>
        <el-button type="primary" size="medium" @click="MultiFree()">批量免费</el-button>
        <el-button type="primary" size="medium" @click="showZipRar()">ZIP/RAR上传</el-button>
        <el-button type="primary" size="medium" @click="updateSelect()">下拉框数据更新</el-button>
      </div>
    </div>

    <el-table :data="tableData"  @selection-change="handelChapterSelection" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="chapter" label="章节" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="story_title" label="专辑" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="is_free" label="免费" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch :active-value=1 @click="chapterFree(scope.row)" :inactive-value=0 :value="scope.row.is_free" ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span v-if="scope.row.status == 0" style="color: #4f5555">未发布</span>
          <span v-if="scope.row.status == 1" style="color: #1d953f">已上架</span>
          <span v-if="scope.row.status == 2" style="color: #f15b6c">已下架</span>
        </template>
      </el-table-column>
<!--      <el-table-column prop="content" label="简介" align="center" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template #default="scope">
          <span class="operation" @click="chapterShow(scope.row)">编辑</span>
          <span class="operation" @click="delChapter(scope.row)">删除</span>
          <span class="operation" v-if="scope.row.status == 1" @click="chapterStatus(scope.row,2)">下架</span>
          <span class="operation" v-if="scope.row.status == 2" @click="chapterStatus(scope.row,1)">上架</span>
          <span class="operation" v-if="scope.row.status == 0" @click="chapterStatus(scope.row,1)" >上架</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getChapterList()" />


    <!--  小说专辑列表  -->
    <el-dialog title="专辑管理" v-model="storyVisible" width="90%" height="500px" top="5vh" :close-on-click-modal="false" >
      <div class="search-box">
        <span class="search-box-title">ID：</span>
        <el-input class="width_100" v-model="story.search.id" placeholder="请小说ID" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">标题：</span>
        <el-input class="width_250" v-model="story.search.title" placeholder="请输入标题关键字" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="story.search.status" filterable placeholder="全部" size="medium" clearable>
          <el-option label="全部" :value=-1 ></el-option>
          <el-option v-for="item in statusList" :key="item" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getstoryList()">查询</el-button>
        <el-button type="primary" size="medium" @click="multiStoryStatus(1)">批量上架</el-button>
        <el-button type="primary" size="medium" @click="multiStoryStatus(2)">批量下架</el-button>
        <el-button type="primary" size="medium" @click="multiStoryDel()">批量删除</el-button>
      </div>
      <el-table :data="story.tableData"  @selection-change="handelStorySelection" :header-cell-style="{background:'#F7F8FA'}">
        <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="image" label="封面图" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <ximg :src="scope.row.image" :width="30" :height="30" alt=""></ximg>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="author" label="作者" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="introduction" label="简介" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span>{{ getStoryTypeById(scope.row.type) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="标签" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-for="item of scope.row.tags" :key="item" >{{item.Title}},</span>
          </template>
        </el-table-column>
        <el-table-column prop="chapters" label="章节" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="is_done" label="完结" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-if="scope.row.is_done == 0">未完结</span>
            <span v-if="scope.row.is_done == 1" style="color: #1d953f">完结</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span v-if="scope.row.status == 0" style="color: #4f5555">未发布</span>
            <span v-if="scope.row.status == 1" style="color: #1d953f">已上架</span>
            <span v-if="scope.row.status == 2" style="color: #f15b6c">已下架</span>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="view_number" label="浏览量" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="goods_count" label="点赞量" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" width="300px" align="center">
          <template #default="scope">
            <span class="operation" v-if="scope.row.is_done == 0" @click="isDone(scope.row,1)">完结</span>
            <span class="operation" v-if="scope.row.is_done == 1" @click="isDone(scope.row,0)">未完结</span>
            <span class="operation" @click="storyEdit(scope.row)">编辑</span>
            <span class="operation" @click="storyDel(scope.row)">删除</span>
            <span class="operation" v-if="scope.row.status == 1" @click="storyStatus(scope.row,2)">下架</span>
            <span class="operation" v-if="scope.row.status == 2" @click="storyStatus(scope.row,1)">上架</span>
            <span class="operation" v-if="scope.row.status == 0" @click="storyStatus(scope.row,1)" >上架</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 50px">
        <page :pager="pager_story" v-if="pager_story.page > 1" @query="getstoryList()" />
      </div>
    </el-dialog>

    <el-dialog :title="storyAddTitle" v-model="storyAddVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">标题</el-col>
        <el-col :span="20">
          <el-input placeholder="请输入专辑标题" v-model="story.add.title"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">作者</el-col>
        <el-col :span="20">
          <el-input placeholder="请输入专辑作者" v-model="story.add.author"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">简介</el-col>
        <el-col :span="20">
          <el-input type="textarea" placeholder="请输入专辑简介" v-model="story.add.introduction"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">封面图</el-col>
        <el-col :span="20">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="uploadImg"
              accept="image/png,image/jpeg,image/jpg,image/gif"
              :before-upload="beforeAvatarUpload">
            <ximg  :enlarge="false" v-if="imageStoryUrl" :src="imageStoryUrl" class="avatar"></ximg>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">分类</el-col>
        <el-col :span="20">
          <el-select class="width_200" v-model="story.add.type">
            <el-option value="0" label="请选择"></el-option>
            <el-option v-for="item of storyType" :key="item" :value="item.id" :label="item.title"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">标签</el-col>
        <el-col :span="20">
          <el-select style="width:90%" v-model="story.add.tags" multiple>
            <el-option v-for="item of storyTag" :key="item" :value="item.id" :label="item.title"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
          <el-button @click="storyAddVisible = false">关闭</el-button>
          <el-button type="primary" @click="saveStory()">添加专辑</el-button>
        </el-col>
      </el-row>
    </el-dialog>


    <el-dialog :title="chapterAddTitle" v-model="chapterAddVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">标题</el-col>
        <el-col :span="20">
          <el-input placeholder="请输入小说标题" v-model="chapterAdd.title"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">专辑</el-col>
        <el-col :span="20">
          <el-select class="width_250" filterable v-model="chapterAdd.story_id" placeholder="请选择">
            <el-option v-for="item of storySelectList" :key="item" :value="item.id" :label="item.title"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">章节</el-col>
        <el-col :span="20">
          <el-input type="number" placeholder="请输入小说作者" v-model="chapterAdd.chapter"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">是否免费</el-col>
        <el-col :span="20">
          <el-switch v-model="chapterAdd.is_free" :active-value=1 :inactive-value=0 ></el-switch>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">内容</el-col>
        <el-col :span="20">
          <el-input type="textarea" :rows="20" v-model="chapterAdd.content" ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
          <el-button @click="chapterAddVisible = false">关闭</el-button>
          <el-button @click="saveChapter()">保存</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="RAR/ZIP上传" v-model="dialogRarVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="uploadZip"
              accept="application/zip,application/x-rar"
              :before-upload="beforeZipUpload">
            <el-button type="primary" size="small">选择文件</el-button>
          </el-upload>
        </el-col>
        <el-col :span="20">
          <el-progress :text-inside="true" :stroke-width="18" :percentage="filePercentage"></el-progress>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">
          目录结构
        </el-col>
        <el-col :span="20">
          <el-input type="textarea" readonly :autosize="{ minRows: 8, maxRows: 18}" value="穿越小说.rar
--穿越小说/亡灵沸腾  (目录就是专辑标题哦,更多设置请在【详细信息.txt】中定义)
    --穿越小说/亡灵沸腾/详细信息.txt
    --穿越小说/亡灵沸腾/第一章.txt
    --穿越小说/亡灵沸腾/我是封面图.png
    --穿越小说/亡灵沸腾/第二章.txt
--穿越小说/六朝云龙
">

          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">
          详细信息.txt内容:
        </el-col>
        <el-col :span="20">
          <el-input type="textarea" readonly :autosize="{ minRows: 13, maxRows: 18}" value="#标题可以不写，不写的话目录就是标题
标题=亡灵沸腾
# #号开头的行，不会被收录，每一行的空格会自动去掉
作者=妄语
简介=我是亡灵沸腾，牛逼plus
# 跟【详细信息.txt】同级目录的图片
封面图=我是封面图.png
#单个整数
分类=1
#多个标签逗号隔开
标签=1,2
"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"></el-col>
        <el-col :span="18">
          <el-button @click="dialogRarVisible=false,filePercentage=0">取消</el-button>
          <el-button @click="saveRar()">保存</el-button></el-col>
      </el-row>
      <div style="height: 20px"></div>
    </el-dialog>


    <el-dialog title="批量设置免费" v-model="chapterFreeVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">章节ID:</el-col>
        <el-col :span="20">
          <span>{{chapterFreeDialog.id_str}}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">是否免费</el-col>
        <el-col :span="20">
          <el-switch v-model="chapterFreeDialog.is_free" :active-value=1 :inactive-value=0 ></el-switch>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
          <el-button @click="chapterFreeVisible = false">关闭</el-button>
          <el-button @click="saveMultiFree()">保存</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import {getTypes,getTags} from '@/api/community/common.js'
import httpClient from "@/config/httpClient.js"
import page from "@/components/page";
import {storyAdd, storyEdit, storyList, storySelectList, storyStatus,storyDel,chapterEdit,chapterAdd,chapterStatus,chapterIsFree} from "@/api/community/story.js"
export default {
  name: "community_story",
  components: {
    page,
  },
  data() {
    return {
      chapterFreeDialog:{
        id_str:"",
        is_free:0,
      },
      chapterFreeVisible:false,
      pager: {total: 0, page: 1, rows: 20},
      pager_story: {total: 0, page: 1, rows: 20},
      search: {
        //搜索
        id: "", //id
        title: [], //时间
        status:null,
        story_id:null,
      },
      statusList:[
        {id:0,value:"未发布"},
        {id:1,value:"上架"},
        {id:2,value:"下架"},
      ],
      storyType:[],
      storyTag:[],
      storySelectList:[], // 小说专辑列表，下拉框用得
      tableData: [
        // {id:1,username:"ssss",title:"我的第一次",book:"海贼王",goods_count:999,is_free:true,status:0},
      ], //数据
      chapterAddTitle:"添加小说章节",
      chapterAdd:{ // 小说添加框
        row:null,
        id:0,
        title:"",
        story_id:null,
        is_free:false,
        chapter:0,
        content:"",
      },
      chapterUploadVisible:false,
      chapterAddVisible:false,
      storyVisible:false,
      storyAddVisible:false,
      storyAddTitle:"小说专辑添加",
      story:{
        pager:{total: 0, page: 1, rows: 10},
        search:{
          id:null,
          title:"",
          checked:0,
          status:-1
        },
        tableData:[
          // {id:1,title:"我的第一次",image:"封面图",author:"海贼王",is_done:0,chapters:3200,introduction:"简介",add_time:"2022-01-01",view_number:999,tags:"标签",goods_count:999,status:1},
        ],
        add:{
          id:0,
          title:"",
          type:null,
          author:"",
          introduction:"",
          tags:[],
          image:"",
        },
      },
      imageStoryUrl:"",// 小说封面图
      chapterMultiSelection:[],
      storyMultiSelection:[],
      image_width:226,
      image_height:321,
      dialogRarVisible:false,
      filePercentage:0,
      per_slice: 1024 * 1024,
      hashInfo: {
        hash:"",
        filename:"",
      }, // 保存到数据库的hash
    };
  },
  mounted() {
    this.updateSelect()
    this.getChapterList()
    // this.getChapterContent()
  },
  methods:{
    saveMultiFree(){
      httpClient("chapterMultiFree").post({is_free:this.chapterFreeDialog.is_free,ids:this.chapterFreeDialog.id_str,type:2}).then((res)=> {
        if(res.code == 0){
          for (const row of this.chapterMultiSelection){
            row.is_free = this.chapterFreeDialog.is_free
          }
          this.chapterFreeVisible = false
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    MultiFree(){
      if (this.chapterMultiSelection.length == 0){
        this.$message({message:"请选择要设置的章节!",type:"error"})
        return
      }
      var ids = []
      for (const row of this.chapterMultiSelection){
        ids.push(row.id)
      }
      this.chapterFreeDialog.id_str = ids.join(",")
      this.chapterFreeVisible = true
    },
    delByMultiSelect(){
      if(this.chapterMultiSelection.length == 0){
        this.$message({message:"请选择要删除的章节!",type:"error"})
        return
      }
      var ids = []
      for(const row of this.chapterMultiSelection){
        ids.push(row.id)
      }
      this.$confirm("删除小说章节: " + (ids.join(",")) + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        this.MultiDel(ids.join(","))
      })

    },
    getChapterContent(url,callback){
      this.$common.showLoading("小说内容解析中!")
      var xml = new XMLHttpRequest()
      // url = "https://qatdo1noills.hskj-peek.com/jycm_story/96c552ad042feb66d0d19b1970e38b7d";
      xml.open("GET",url,true)
      var self = this
      xml.onreadystatechange = function () {
        self.$common.hideLoading()
        if (xml.status == 200 && xml.readyState == 4){
          // console.log(xml.responseText)
          callback(xml.responseText)
        }else{
          callback("")
        }
      }
      xml.timeout = 30000
      xml.send()
    },
    isDone(row,is_done){
      httpClient("StoryDone").post({is_done:is_done,id:row.id}).then((res)=> {
        if(res.code == 0){
          row.is_done = is_done
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    saveRar(){
      if (this.hashInfo.hash == ""){
        this.$message({message:"请选择要上传的文件!",type:"error"})
        return
      }
      this.$common.showLoading()
      httpClient("importByRar").post({type:2,hash:this.hashInfo.hash}).then((res)=>{
        console.log(res)
        this.$common.hideLoading()
        if (res.code == 0){
          this.filePercentage = 0
          this.hashInfo = {
            hash:"",
            filename:"",
          }
          this.$message({message:"导入完毕!",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    beforeZipUpload(file){
      console.log(file)
    },
    uploadZip(param){
      this.filePercentage = 0
      if (param.file.size > 1024 * this.per_slice){
        // 不能超过1G
        this.$message({message:"上传的压缩包不可以超过1G",type:"error"})
        return
      }
      this.$common.showLoading("正在上传压缩包!")
      httpClient().setUploadUrl("").uploadSlice(param.file, (percentage)=>{
        this.filePercentage = percentage
      },(res)=>{
        console.log(res)
        this.$common.hideLoading()
        this.hashInfo = res
        this.filePercentage = 100
        // 服务器解压，然后导入数据库
      },(err)=>{
        console.log(err)
      })
    },
    showZipRar(){
      this.dialogRarVisible = true
    },
    chapterFree(row){
      this.$common.showLoading()
      chapterIsFree([
        {key:"id",val:row.id},
        {key:"is_free",val:row.is_free == 0?1:0},
      ]).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          row.is_free = row.is_free == 0?1:0
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    handelChapterSelection(rows){
      this.chapterMultiSelection = rows;
    },
    handelStorySelection(rows){
      this.storyMultiSelection = rows;
    },
    chapterStatusMulti(status){
      if(this.chapterMultiSelection.length == 0){
        this.$message({message:"请先选择章节",type:"error"})
        return;
      }
      var ids = [];
      for(const row of this.chapterMultiSelection){
        ids.push(row.id)
      }
      var title = "下架"
      if(status == 1){
        title = "上架"
      }
      this.$confirm("批量"+title+": " + ids.join(",") + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=> {
        this.$common.showLoading()
        chapterStatus([
          {key:"ids",val:ids.join(",")},
          {key:"status",val:status},
        ]).then((res)=>{
          this.$common.hideLoading()
          if (res.code==0){
            for(const row of this.chapterMultiSelection){
              row.status = status
            }
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      })

    },
    chapterStatus(row,status){
      this.$common.showLoading()
      chapterStatus([
        {key:"ids",val:row.id},
        {key:"status",val:status},
      ]).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          row.status = status
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    storyDel(row){
      this.$confirm("删除小说专辑: " + row.title + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        this.$common.showLoading("删除小说专辑:"+row.title)
        storyDel([
          {key:"id",val:row.id}
        ]).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.getstoryList()
          }else{
            this.$message({message:"添加小说章节失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      })
    },
    MultiDel(ids){
      this.$common.showLoading("删除小说章节:"+ids)
      httpClient("DelStoryChapters").post({"ids":ids}).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.getChapterList()
        }else{
          this.$message({message:"删除小说章节失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    delChapter(row){
      this.$confirm("删除小说章节: " + row.title + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        this.MultiDel(row.id)
      })

    },
    getStoryById(story_id){
      for(const story of this.storySelectList){
        if (story.id == story_id){
          return story.title
        }
      }
      return "未知专辑ID:"+story_id
    },
    saveChapter(){
      if (this.chapterAdd.id > 0){
        // 编辑
        var saveData = [
          {key:"title",val:this.chapterAdd.title},
          {key:"is_free",val:this.chapterAdd.is_free},
          {key:"chapter",val:this.chapterAdd.chapter},
          {key:"story_id",val:this.chapterAdd.story_id},
          // {key:"content",val:this.$common.gzip(this.chapterAdd.content)},
          {key:"content",val:this.chapterAdd.content},
          {key:"id",val:this.chapterAdd.id},
        ]
        chapterEdit(saveData).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.chapterAddVisible = false
            for(var i in saveData){
              this.chapterAdd.row[saveData[i].key] = saveData[i].val
            }
          }else{
            this.$message({message:"添加小说章节失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      }else{
        // 添加
        this.$common.showLoading("正在添加小说专辑!")
        chapterAdd([
          {key:"title",val:this.chapterAdd.title},
          {key:"is_free",val:this.chapterAdd.is_free},
          {key:"chapter",val:this.chapterAdd.chapter},
          {key:"story_id",val:this.chapterAdd.story_id},
          // {key:"content",val:this.$common.gzip(this.chapterAdd.content)},
          {key:"content",val:this.chapterAdd.content},
        ]).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.chapterAddVisible = false
            this.getChapterList()
          }else{
            this.$message({message:"添加小说章节失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      }
    },
    storyStatus(row,status){
      this.$common.showLoading()
      storyStatus([
        {key:"ids",val:row.id},
        {key:"status",val:status},
      ]).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          row.status = status
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    updateSelect(){
      this.getStoryType()
      this.getStoryTags()
      this.getStorySelectList()
    },
    uploadImg(param){
      // 750*360
      var canvas = document.createElement("canvas")
      // 读取图片的宽高
      var img = document.createElement("img")
      img.src =  URL.createObjectURL(param.file)
      img.width = this.image_width
      img.height = this.image_height
      var self = this
      img.onload = function (){
        canvas.width = img.width
        canvas.height = img.height
        canvas.getContext("2d").drawImage(img,0,0,img.width,img.height)
        self.imageStoryUrl = canvas.toDataURL(param.file.type);
        // 开始上传图片
        self.startUploadImg(self.base64ToFile(self.imageStoryUrl,param.file.name)) //  开始上传图片
      }
    },
    startUploadImg(file){
      // 开始上传文件到服务器
      var _headers = {
        "is_compress":"false",
        "name":"community",
        "is-encode":"true",
      }
      this.$common.showLoading("正在上传封面图!")
      httpClient().setHeader(_headers).upload(file).then((res)=>{
        console.log(res)
        this.$common.hideLoading()
        if (res.code==0){
          this.story.add.image = res.data.filename
          this.$message({message:"图片上传成功!",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    beforeAvatarUpload(file){
      // 显示图片
      if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" ){
        this.$message({message:"文件类型["+file.type+"]不正确!",type:"error"})
        return
      }
      // 大小检测？
    },
    saveStory(){
      console.log(this.story.add)
      if (this.story.add.title == ""){
        this.$message({message:"请输入小说的标题",type:"error"})
        return
      }
      if (this.story.add.author == ""){
        this.$message({message:"请输入小说的作者",type:"error"})
        return
      }
      if (this.story.add.introduction == ""){
        this.$message({message:"请输入小说的简介",type:"error"})
        return
      }
      if (this.story.add.id > 0){
        // 编辑
        storyEdit([
          {key:"id",val:this.story.add.id},
          {key:"title",val:this.story.add.title},
          {key:"author",val:this.story.add.author},
          {key:"chapter",val:this.story.add.chapter},
          {key:"introduction",val:this.story.add.introduction},
          {key:"image",val:this.story.add.image},
          {key:"is_done",val:this.story.add.is_done},
          {key:"type",val:this.story.add.type},
          {key:"tags",val:this.story.add.tags.join(",")},
          // {key:"vip_show",val:this.story.add.vip_show},
        ]).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.storyAddVisible = false
            this.getstoryList()
            this.getStorySelectList()
          }else{
            this.$message({message:"添加小说专辑失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      }else{
        // 添加
        this.$common.showLoading("正在添加小说专辑!")
        storyAdd([
          {key:"title",val:this.story.add.title},
          {key:"author",val:this.story.add.author},
          {key:"chapter",val:this.story.add.chapter},
          {key:"introduction",val:this.story.add.introduction},
          {key:"image",val:this.story.add.image},
          {key:"is_done",val:this.story.add.is_done},
          {key:"type",val:this.story.add.type},
          {key:"tags",val:this.story.add.tags.join(",")},
          // {key:"vip_show",val:this.story.add.vip_show},
        ]).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.storyAddVisible = false
            this.getstoryList()
            this.getStorySelectList()
          }else{
            this.$message({message:"添加小说专辑失败:"+res.msg,type:"error"})
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      }
    },
    getChapterList(){
      httpClient("GetStoryChapters").post({page:this.pager.page,
          row:this.pager.rows,
          id:this.search.id,
          title:this.search.title,
          checked:this.search.checked,
          status:this.search.status,
          story_id:this.search.story_id}).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:"读取小说章节失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    chapterShow(row){
      this.chapterAddVisible = true
      this.chapterAdd = {
        id:0,
        title:"",
        story_id:null,
        is_free:0,
        chapter:0,
        content:"",
        row:null,
      }
      if(row !== undefined){
        console.log(row)
        this.chapterAddTitle = "编辑小说章节,ID:"+row.id
        this.chapterAdd = {
          row:row,
          id:row.id,
          title:row.title,
          story_id:row.story_id,
          is_free:row.is_free,
          chapter:row.chapter,
          // content:this.$common.ungzip(row.content),
        }
        this.getChapterContent(this.$store.getImage(row.url),(txt)=>{
          this.chapterAdd.content = txt
        })
      }else{
        this.chapterAddTitle = "添加小说章节"
      }
    },
    chapterUploadImage(row){
      console.log(row)
      this.chapterUploadVisible = true
      this.chapterUpload.row = row;
    },
    showstoryList(){
      this.storyVisible = true
      this.getstoryList()
    },
    multiStoryStatus(status){
      if(this.storyMultiSelection.length == 0){
        this.$message({message:"请选择小说专辑!",type:"error"})
        return;
      }
      var ids = []
      for(const row of this.storyMultiSelection){
        ids.push(row.id)
      }
      this.$common.showLoading()
      httpClient("BatchShelvesStory").post({status:status,ids:ids.join(",")}).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          for(const row of this.storyMultiSelection){
            row.status = status
          }
        }else{
          this.$message({message:"读取小说分类失败:"+res.msg,type:"error"})
        }
      })
    },
    multiStoryDel(){
      if(this.storyMultiSelection.length == 0){
        this.$message({message:"请选择小说专辑!",type:"error"})
        return;
      }
      var ids = []
      for(const row of this.storyMultiSelection){
        ids.push(row.id)
      }
      this.$confirm("删除小说专辑: " + ids.join(",") + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=> {
        this.$common.showLoading()
        httpClient("DelStory").post({id:ids.join(",")}).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.getstoryList()
          }else{
            this.$message({message:"读取小说分类失败:"+res.msg,type:"error"})
          }
        })
      })
    },
    getstoryList(){
      storyList([
        {key:"page",val:this.pager_story.page},
        {key:"row",val:this.pager_story.row},
        {key:"id",val:this.story.search.id},
        {key:"title",val:this.story.search.title},
        {key:"checked",val:this.story.search.checked},
        {key:"status",val:this.story.search.status},
      ]).then((res)=>{
        if (res.code == 0){
          console.log(res.data)
          this.story.tableData = res.data.list
          if (this.pager_story.page == 1){
            this.pager_story.total = res.data.total
          }
        }else{
          this.$message({message:"读取小说分类失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    storyEdit(row){
      this.storyAddVisible = true
      this.story.add = {
        id:0,
        title:"",
        type:null,
        author:"",
        introduction:"",
        tags:[],
        image:"",
      }
      this.imageStoryUrl = ""
      if (row){
        this.storyAddTitle = "编辑小说专辑,ID:"+row.id
        var select_tags = []
        if(row.tags){
          for(const tag of row.tags){
            select_tags.push(tag.Id)
          }
        }
        this.story.add = {
          id:row.id,
          title:row.title,
          type:row.type,
          author:row.author,
          introduction:row.introduction,
          tags:select_tags,
          image:row.image,
        }
        this.imageStoryUrl = this.$store.getImage(row.image)
      }else{
        this.storyAddTitle = "添加小说专辑"
      }
    },
    getStoryType(){
      getTypes(2).then((res)=>{
        if (res.code == 0){
          this.storyType = res.data.list
        }else{
          this.$message({message:"读取小说分类失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    getStoryTypeById(type){
      for(var i in this.storyType){
        if (this.storyType[i].id == type){
          return this.storyType[i].title
        }
      }
      if(type == 0){
        return ""
      }
      return "未知分类:"+type
    },
    getStoryTags(){
      getTags(2).then((res)=>{
        if (res.code == 0){
          this.storyTag = res.data
        }else{
          this.$message({message:"读取小说分类失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    getStorySelectList(){
      storySelectList().then((res)=>{
        if (res.code == 0){
          this.storySelectList = res.data
        }else{
          this.$message({message:"读取小说专辑下拉框失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    }
  }
}
</script>

<style>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>